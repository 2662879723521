import React from 'react'
import classnames from 'classnames'

const InputBlock = ({ label, errors, handler, name, value, type, placeholder, onInputBlur }) => {
  const errorClass = classnames({
    'error-field': true,
    'error-field--visible': errors[name]
  })

  const inputClass = classnames({
    'input-text': true,
    'input-text--error': errors[name]
  })

  return (
    <div className='input-block'>
      {
        label &&
        <label htmlFor={name} className='input-block__label base-text'>
          {label}
        </label>
      }
      <div className='input-block__input'>
        <input
          id={name}
          value={value}
          onChange={handler(name)}
          className={inputClass}
          type={type}
          placeholder={placeholder || ''}
          onBlur={onInputBlur}
        />
        <span className={errorClass}>
          {errors[name]}
        </span>
      </div>
    </div>
  )
}

export default InputBlock


// export function convertFBListToArray(list) {
//   if (!list) return null
//
//   const data = []
//
//   list.forEach(s => {
//     data.push({
//       uid: s.key,
//       ...s.val()
//     })
//   })
//
//   return data
// }

export function convertObjectToArray(obj) {
  if (!obj) return []

  const data = []

  for (const property in obj) {
    data.push({
      uid: property,
      ...obj[property]
    })
  }

  return data
}

export function getDateString(ISOString) {
  const date = new Date(ISOString)

  const year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()

  if (day < 10) day = '0' + day
  if (month < 10) month = '0' +  month

  return day + '.' + month + '.' + year
}

export function getCategoryString(categories) {
  let catString = ''

  categories.forEach((cat, i) => {
    if (i === categories.length - 1) {
      catString += `${cat}`
    } else {
      catString += `${cat} / `
    }
  })

  return catString
}

export function convertFBCodeMessage(error) {
  switch (error.code) {
    case 'auth/user-not-found':
    case 'auth/wrong-password':
      return 'Wrong email or password.'
    case 'auth/email-already-in-use':
      return 'Email is already in use by another account.'
    default:
      return error.message
  }
}

export function shuffle(array) {
  let currentIndex = array.length, temporaryValue, randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

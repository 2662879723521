import React, { Component } from 'react'

class Home extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div className='home'>
        <img src='https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/static%2F367_joint.jpg?alt=media&token=4ae4128d-1309-4778-b4b8-07168e870a6c' width='280' alt='blue joint' />
      </div>
    )
  }
}

export default Home

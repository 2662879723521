import React from 'react'
import Parser from 'html-react-parser'

function TextSection(props) {
  const { textParts, bold } = props
  let textClass = 'exhibition__text base-text'

  // if (bold) textClass += ' exhibition__text base-text'

  return (
    <div>
      {
        textParts.map((text, i) => <p key={i} className={textClass}>{Parser(text)}</p>)
      }
    </div>
  )
}

export default TextSection

import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import classnames from 'classnames'

class Header extends Component {
  state = {
    navOpened: false
  }

  componentDidUpdate(prevProps) {
    this.unlisten = this.props.history.listen((location, action) => {
      window.scrollTo(0, 0)
      this.closeNav()
    })
  }

  componentWillUnmount() {
    this.unlisten()
  }

  render() {
    const { user, logOut, url } = this.props
    const { navOpened } = this.state

    const exhibitionsRoute = url === '/exhibitions'
    const work2Route = url === '/work2'
    const contactRoute = url === '/contact'
    const photoRoute = url === '/photo'
    const workRoute = url.includes('/exhibition/') || url.includes('/work1/') || url.includes('/work2/')

    const headerClass = classnames({
      'header': true,
      'header--nav-open': navOpened,
      'header--contact': contactRoute,
      'header--photo': photoRoute,
      'header--work2': work2Route,
      'header--exhibition': exhibitionsRoute,
      'header--work': workRoute
    })

    const burgerClass = classnames({
      'header__burger': true,
      'header__burger--open': navOpened
    })

    const navClass = classnames({
      'header__nav': true,
      'header__nav--open': navOpened
    })

    const activeNavLinkClass = classnames({
      'header__nav-link--active': true,
      // 'header__nav-link--active-blue': contactRoute
    })

    return (
      <div className={headerClass}>
        <div className='header__inner'>
          <div className='header__logo'>
            <NavLink exact to='/' className='header__logo-link'>
              Victor Zabuga
              <img src='/menu/7-01.svg' width='230' alt='Victor Zabuga' />
            </NavLink>
          </div>
          <div className={burgerClass} onClick={this.toggleNav}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <nav className={navClass}>
            <ul>
              <li>
                <NavLink to='/exhibitions' className='header__nav-link' activeClassName={activeNavLinkClass}>
                  exhibitions
                  <img src='/menu/1-01.svg' height='30' alt='exhibitions' />
                </NavLink>
              </li>
              <li>
                <NavLink to='/work1' className='header__nav-link' activeClassName={activeNavLinkClass}>
                  work1
                  <img src='/menu/2-01.svg' height='30' alt='work1' />
                </NavLink>
              </li>
              <li>
                <NavLink to='/work2' className='header__nav-link' activeClassName={activeNavLinkClass}>
                  work2
                  <img src='/menu/3-01.svg' height='30' alt='work2' />
                </NavLink>
              </li>
              <li>
                <NavLink to='/photo' className='header__nav-link' activeClassName={activeNavLinkClass}>
                  photo
                  <img src='/menu/4-01.svg' height='30' alt='photo' />
                </NavLink>
              </li>
              <li>
                <NavLink to='/shop' className='header__nav-link' activeClassName={activeNavLinkClass}>
                  shop
                  <img src='/menu/6-01.svg' height='30' alt='shop' />
                </NavLink>
              </li>
              <li>
                <NavLink to='/contact' className='header__nav-link' activeClassName={activeNavLinkClass}>
                  contact
                  <img src='/menu/5-01.svg' height='30' alt='contact' />
                </NavLink>
              </li>
              {
                user ?
                <li>
                  <NavLink to='/admin' className='header__nav-link' activeClassName={activeNavLinkClass}>
                    admin
                  </NavLink>
                </li> : null
              }
              {
                user ?
                <li>
                  <button className='header__nav-link' onClick={logOut}>
                    logout
                  </button>
                </li> : null
              }
            </ul>
          </nav>
        </div>
      </div>
    )
  }

  toggleNav = () => this.setState({ navOpened: !this.state.navOpened })

  closeNav = () => this.setState({ navOpened: false })
}

export default withRouter(Header)

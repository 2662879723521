import React, { Component } from 'react'

class Contact extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div className='contact'>
        <h1 className='contact__header page-header base-header'>
          Contact
        </h1>
        <div className='contact__img'>
          <img src='https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/static%2F367_2.jpg?alt=media&token=3049c40a-dc58-4a68-b27e-5c34537c176a' width='200' height='200' alt='zabuga ava' />
        </div>
        <div className='contact__links base-text'>
          <div className='contact__link'>
            <a href="mailto:zabugabooking@gmail.com">
              zabugabooking@gmail.com
            </a>
          </div>
          <div className='contact__link'>
            <a href='https://instagram.com/_367_' target='_blank' rel='noopener noreferrer'>
              instagram
            </a>
          </div>
          <div className='contact__link'>
            <a href='https://www.facebook.com/zbg367' target='_blank' rel='noopener noreferrer'>
              facebook
            </a>
          </div>
          <div className='contact__link'>
            <a href='https://vk.com/id43783939' target='_blank' rel='noopener noreferrer'>
              vk
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default Contact

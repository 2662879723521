import React, { Component } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { auth } from '../../firebase'
import InputBlock from '../common/InputBlock'
import validator from 'email-validator'
import Loader from '../common/Loader'
import { convertFBCodeMessage } from '../../utils'

class Login extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      loading: false,
      errors: {},
      error: null
    }
  }

  render() {
    const { user } = this.props
    const { email,
            password,
            errors,
            loading,
            error } = this.state
    
    if (loading) return <Loader />

    if (user) return <Redirect to='/admin' />

    return (
      <form className='common-form' onSubmit={this.onSubmit}>
        <h3 className='page-header base-header'>hi man. log in plz.</h3>
          { error && <p className='error-field'>{error}</p> }
          <InputBlock
            label='Email'
            errors={errors}
            handler={this.onInputChange}
            name='email'
            value={email}
            type='text'
          />
          <InputBlock
            label='Password'
            errors={errors}
            handler={this.onInputChange}
            name='password'
            value={password}
            type='password'
          />
          <p className='common-form__submit '>
            <button type='submit' className='submit-btn'>
              GO
            </button>
          </p>
      </form>
    )
  }

  onInputChange = element => e => {
    this.setState({
      [element]: e.target.value
    })
  }

  onSubmit = (event) => {
    event.preventDefault()
    this.validate()
  }

  validate = () => {
    const { email, password } = this.state
    const errors = {}
    if (!email) errors.email = 'Required field'
    if (email && !validator.validate(email)) errors.email = 'Incorrect email format'

    if (!password) errors.password = 'Required field'
    if (password && password.length < 6) errors.password = 'Password is too short'

    if (Object.keys(errors).length) {
      this.setState({ errors })
    } else if (email && password) {
      this.setState({
        errors: errors,
        loading: true,
      }, this.doSignIn)
    }
  }

  doSignIn = () => {
    const { history } = this.props
    const { email,
            password } = this.state

    auth.doSignInWithEmailAndPassword(email, password)
      .then((res) => {
        history.push('/admin')
      })
      .catch(error => {
        this.setState({
          error: convertFBCodeMessage(error),
          loading: false
        })
      })
  }
}

export default withRouter(Login)

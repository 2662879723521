const appName = 'zabuga-67ed0'

export const config = {
  apiKey: 'AIzaSyCHt3nxGDdW0XSt6E8LRYV_u6TWijC6cGI',
  authDomain: `${appName}.firebaseapp.com`,
  databaseURL: `https://${appName}.firebaseio.com`,
  projectId: appName,
  storageBucket: `${appName}.appspot.com`,
  messagingSenderId: '841593216244'
}

import React from 'react'
import ReactDOM from 'react-dom'
import Root from './Root'
// import * as serviceWorker from './serviceWorker'

ReactDOM.render(<Root />, document.getElementById('root'))

// serviceWorker.register()

function unregister() {
  if (typeof navigator !== 'undefined' && 'serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
      for (let registration of registrations) {
        console.log('unregister')
        registration.unregister()
      }
    })
  }
}

unregister();

import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

class Works extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    let { works, work2 } = this.props

    return (
      <div className='works'>
        <h1 className='works__header base-header page-header'>
          { work2 ? 'Work2' : 'Work1' }
        </h1>
        <div className='works__list'>
        {
          works.map((work) => {
            const { uid, name, images } = work
            const workLink = work2 ? `/work2/${uid}` : `/work1/${uid}`

            return (
              <div key={uid} className='works__work'>
                <div>
                  <NavLink to={workLink}>
                    <img src={images[0]} width='280' height='200' alt={`${name} work`} />
                  </NavLink>
                </div>
                {/*}<NavLink to={`/work/${uid}`}>
                  <h2 className='secondary-header'>
                    {name}
                  </h2>
                </NavLink>*/}
              </div>
            )
          })
        }
        </div>
      </div>
    )
  }
}

export default Works

export const photos = [
  'https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/photo%2Fzabuga-photo-1.jpg?alt=media&token=800a89c4-e649-4326-9a00-dffc6521c55e',
  'https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/photo%2Fzabuga-photo-10.jpg?alt=media&token=598d24f4-b147-497e-a22b-4b78fcec2708',
  'https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/photo%2Fzabuga-photo-11.jpg?alt=media&token=cb8e9379-1a9d-4989-b200-dbbc09fa2f64',
  'https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/photo%2Fzabuga-photo-12.jpg?alt=media&token=e2a6007f-92bc-47c5-9cda-816b859c8807',
  'https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/photo%2Fzabuga-photo-13.jpg?alt=media&token=43427853-19ef-4675-b74c-d61335afe07b',
  'https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/photo%2Fzabuga-photo-14.jpg?alt=media&token=475ba6d9-6c0a-4211-99a2-b09bd642b0f3',
  'https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/photo%2Fzabuga-photo-15.jpg?alt=media&token=302cc4ba-3525-4a08-a06e-b2b2e13fb63c',
  'https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/photo%2Fzabuga-photo-16.jpg?alt=media&token=a0c8d423-b6df-4265-9b0a-878e4cc8a866',
  'https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/photo%2Fzabuga-photo-17.jpg?alt=media&token=3a617080-93b0-4ab3-bb2c-16d97ff686cd',
  'https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/photo%2Fzabuga-photo-18.jpg?alt=media&token=39e6c096-322a-47dc-9798-fe53e0329aec',
  'https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/photo%2Fzabuga-photo-19.jpg?alt=media&token=6dde3057-ded9-4fc7-86b3-6d7080cefe68',
  'https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/photo%2Fzabuga-photo-2.jpg?alt=media&token=da96c627-2c13-4a09-8a36-596661053d24',
  'https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/photo%2Fzabuga-photo-20.jpg?alt=media&token=73cce532-ae17-45bf-a494-574d40d09c89',
  'https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/photo%2Fzabuga-photo-21.jpg?alt=media&token=9b2116ed-5b7e-460a-8202-8be66ce21a32',
  'https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/photo%2Fzabuga-photo-22.jpg?alt=media&token=764791cb-5f41-4be3-bb6d-55aeda7029d5',
  'https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/photo%2Fzabuga-photo-23.jpg?alt=media&token=5bdef302-0eb2-4249-bc87-972de397acfc',
  'https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/photo%2Fzabuga-photo-24.jpg?alt=media&token=84388915-8a12-422b-bec0-3baf45ea4bed',
  'https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/photo%2Fzabuga-photo-25.jpg?alt=media&token=f563c1a6-45ca-41b0-a7ca-7ba7b9d94e3d',
  'https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/photo%2Fzabuga-photo-26.jpg?alt=media&token=6b7c16c7-77a5-4c83-832e-6b481f976a86',
  'https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/photo%2Fzabuga-photo-27.jpg?alt=media&token=63f7124b-9283-424f-9fec-0eac2e2c197c',
  'https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/photo%2Fzabuga-photo-28.jpg?alt=media&token=b8e93c90-8d43-4ec7-a128-1f992247317e',
  'https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/photo%2Fzabuga-photo-3.jpg?alt=media&token=554a449c-a9b0-4b92-8e60-46dd8702a7c7',
  'https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/photo%2Fzabuga-photo-4.jpg?alt=media&token=765e6e97-632d-48fb-b0b5-955a0939f755',
  'https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/photo%2Fzabuga-photo-5.jpg?alt=media&token=a7d25442-2cc8-4c08-aeaf-40736b59f0d3',
  'https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/photo%2Fzabuga-photo-6.jpg?alt=media&token=7f2a0be4-4cfb-416c-848a-1126991e78df',
  'https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/photo%2Fzabuga-photo-7.jpg?alt=media&token=4ee09cd8-6edf-4be8-9bf1-41b201f27cea',
  'https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/photo%2Fzabuga-photo-8.jpg?alt=media&token=1b48a203-940f-40e6-a53f-b98f736c68a8',
  'https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/photo%2Fzabuga-photo-9.jpg?alt=media&token=934c8717-bd0f-4689-9c77-9ac5d0f8a2e2'
]

import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

class Exhibitions extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const { exhibitions } = this.props

    return (
      <div className='exhibitions'>
        <h1 className='exhibitions__header base-header page-header'>
          Exhibitions
        </h1>
        <div className='exhibitions__list'>
        {
          exhibitions.reverse().map((exhibition) => {
            const { uid, images, name } = exhibition

            return (
              <div key={uid} className='exhibitions__item'>
                <div>
                  <NavLink to={`/exhibition/${uid}`}>
                    <img src={images[0]} width='280' height='200' alt={`${name} event`} />
                  </NavLink>
                </div>
                <NavLink to={`/exhibition/${uid}`}>
                  <h2 className='base-header'>
                    {name}
                  </h2>
                </NavLink>
              </div>
            )
          })
        }
        </div>
      </div>
    )
  }
}

export default Exhibitions

import React, { Component } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import classnames from 'classnames'
import Home from './routes/Home'
import Header from './common/Header'
// import Footer from './common/Footer'
import Works from './routes/Works'
import Work from './routes/Work'
import Photo from './routes/Photo'
import Shop from './routes/Shop'
import Exhibitions from './routes/Exhibitions'
import Exhibition from './routes/Exhibition'
import Contact from './routes/Contact'
import Login from './routes/Login'
import Admin from './routes/Admin'
import { works1, works2 } from '../data/works'
import { photos } from '../data/photo'
import { exhibitions } from '../data/exhibitions'
import { convertObjectToArray, shuffle } from '../utils'
import { auth, firebase } from '../firebase'

class App extends Component {
  state = {
    user: null,
    // loading: false
  }

  componentDidMount() {
    firebase.auth.onAuthStateChanged(authUser => {
      authUser
        ? this.setState({ user: authUser }, this.loadData)
        : this.setState({ user: null }, this.loadData)
    })
  }

  loadData() {
    // db.onceGetData()
    //   .then(snapshot => this.setState({
    //     ticker: convertFBListToArray(snapshot)[0],
    //   }))
  }

  render() {
    const { user } = this.state
    const url = this.props.history.location.pathname

    const works1Array = convertObjectToArray(works1)
    const works2Array = convertObjectToArray(works2)

    const exhibitionsArray = convertObjectToArray(exhibitions)

    const homeRoute = url === '/'
    const exhibitionsRoute = url === '/exhibitions'
    const work2Route = url === '/work2'
    const contactRoute = url === '/contact'
    const photoRoute = url === '/photo'
    const workRoute = url.includes('/exhibition/') || url.includes('/work1/') || url.includes('/work2/')

    if (homeRoute) document.body.style.backgroundColor = '#B3ECFF'
    if (exhibitionsRoute) document.body.style.backgroundColor = '#ff8800'
    if (work2Route) document.body.style.backgroundColor = '#FFDB58'
    if (contactRoute) document.body.style.backgroundColor = '#F6C7F5'
    if (photoRoute) document.body.style.backgroundColor = '#b2ec5d'
    if (workRoute) document.body.style.backgroundColor = 'white'

    const appClass = classnames({
      'app': true,
      'app--contact': contactRoute,
      'app--photo': photoRoute,
      'app--work2': work2Route,
      'app--exhibition': exhibitionsRoute,
      'app--work': workRoute
    })

    // {
    //   url === '/' ? null : <Footer />
    // }

    return (
      <div className={appClass}>
        <Header
          user={user}
          logOut={this.logOut}
          url={url}
        />
        <main className='app__main'>
          <Switch>
            <Route exact path='/' render={(props) => <Home {...props} /> } />
            <Route exact path='/work1' render={(props) => <Works {...props} works={shuffle(works1Array)} /> } />
            <Route exact path='/work2' render={(props) => <Works {...props} works={shuffle(works2Array)} work2={true} /> } />
            <Route exact path='/work1/:workId' render={({ match }) =>
              <Work work={works1[match.params.workId]} work1={true} />
              }
            />
            <Route exact path='/work2/:workId' render={({ match }) =>
              <Work work={works2[match.params.workId]} work2={true} />
              }
            />
            <Route exact path='/photo' render={(props) =>
              <Photo {...props} photos={shuffle(photos)} />
              }
            />
            <Route exact path='/exhibitions' render={(props) => <Exhibitions {...props} exhibitions={exhibitionsArray} /> } />
            <Route exact path='/exhibition/:exhibitionId' render={({ match }) =>
              <Exhibition exhibition={exhibitions[match.params.exhibitionId]} /> }
            />
            <Route exact path='/contact' render={(props) => <Contact {...props} /> } />

            <Route exact path ='/shop' render={(props) => <Shop user={user} /> }/>
            <Route exact path ='/login' render={(props) => <Login user={user} /> }/>
            <Route exact path ='/admin' render={(props) => <Admin user={user} /> }/>
            <Redirect to='/' />
          </Switch>
        </main>
      </div>
    )
  }

  logOut = () => {
    auth.doSignOut()
      .then(() => {
        this.setState({
          user: null,
          loading: false
        })
      })
      .catch((error) => {
        this.setState({ loading: false })
      })
  }
}

export default withRouter(App)

import React, { Component } from 'react'

class Shop extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div className='stuff'>
        <h1 className='stuff__header base-header page-header'>
          Shop
        </h1>
        <div className='stuff__list'>
        ...in progress...
        </div>
      </div>
    )
  }
}

export default Shop

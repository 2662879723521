export const works1 = {
  "banana-and-mandarin": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work1%2Fbanana-and-mandarin.jpg?alt=media&token=025926fe-0b24-4264-8019-2eb94b83df63"
    ],
    "year": 18
  },
  "blue-pot": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work1%2Fblue-pot.jpg?alt=media&token=b997aec6-0a72-4f8b-8a12-db29de39b869"
    ],
    "year": 18
  },
  "cat-face": {
    "name": "Cat",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work1%2Fcat-face.jpg?alt=media&token=9fec6844-db8d-43ed-bd25-82e1ecab1c99"
    ],
    "year": 18
  },
  "cat-in-the-field": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work1%2Fcat-in-the-field.jpg?alt=media&token=e00cc77d-bceb-4890-965c-6da4ca80c564"
    ],
    "year": 18
  },
  "chair": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work1%2Fchair.jpg?alt=media&token=e03dc348-e5ae-411a-a16f-ceadacd39460"
    ],
    "year": 18
  },
  "circus18": {
    "name": "Circus",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work1%2Fcircus18.jpg?alt=media&token=20a25bca-f285-4960-98d5-81d4e7569609"
    ],
    "year": 18
  },
  "cloud-lines": {
    "name": "Clouds",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work1%2Fcloud-lines.jpg?alt=media&token=47dae4bf-eee6-4bfa-936a-ccc700b94652"
    ],
    "year": 18
  },
  "compton": {
    "name": "Compton",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work1%2Fcompton.jpg?alt=media&token=fffbd760-24e0-4aca-8b73-383a54c542cf"
    ],
    "year": 18
  },
  "detroit": {
    "name": "Detroit",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work1%2Fdetroit19.JPG?alt=media&token=42c8423c-3487-46b8-80b6-d399ffe72ff2"
    ],
    "year": 18
  },
  "spark": {
    "name": "Spark",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work1%2Fflowers-in-bottle.jpg?alt=media&token=43c71b84-99c8-4807-992d-68045151de79"
    ],
    "year": 18
  },
  "horse": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work1%2Fhorse.jpg?alt=media&token=89d72b0d-4c05-4987-973a-4206f7e3aa59"
    ],
    "year": 18
  },
  "hot-day": {
    "name": "Hot day",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work1%2Fhot-day.jpg?alt=media&token=910449ae-b3a1-4941-9f19-e7fffec4b2e1"
    ],
    "year": 18
  },
  "human-vices": {
    "name": "Human vices",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work1%2Fhuman-vices.jpg?alt=media&token=9dbbaa6b-ceac-4578-801b-118c640f91ce"
    ],
    "year": 18
  },
  "la-putana": {
    "name": "La Putana",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work1%2Fla-putana.jpg?alt=media&token=6ce225e3-1800-44a3-af4a-8c4957857bb4"
    ],
    "year": 18
  },
  "loneliness": {
    "name": "Loneliness",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work1%2Floneliness.jpg?alt=media&token=629c1f03-0e94-4d3c-a77b-c8f9a3e05284"
    ],
    "year": 18
  },
  "man-in-red-beret": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work1%2Fman-in-red-beret.jpg?alt=media&token=ad4a0cf3-a339-4f73-8b70-50b0b374deae"
    ],
    "year": 18
  },
  "mc-clown": {
    "name": "Mc",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work1%2Fmc-clown.jpg?alt=media&token=8b9f9b08-8614-4398-81ca-0a332c89bc6a"
    ],
    "year": 18
  },
  "naked-man": {
    "name": "Naded man",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work1%2Fnaked-man.jpg?alt=media&token=325aeec3-fdbb-4b1e-a167-d9e76c84db12"
    ],
    "year": 18
  },
  "naked-woman": {
    "name": "Naked woman",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work1%2Fnaked-woman.jpg?alt=media&token=4510911e-389b-4c0b-b2cf-b4f0c817b31f"
    ],
    "year": 18
  },
  "piero": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work1%2Fpiero.jpg?alt=media&token=5a334cca-0cc3-4ecc-ba62-47ef32e17c15"
    ],
    "year": 18
  },
  "rita": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work1%2Frita.jpg?alt=media&token=63688caf-c97a-41d4-a789-ab1c6712003a"
    ],
    "year": 19
  },
  "spb": {
    "name": "Spb",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work1%2Fspb.jpg?alt=media&token=02c800d9-8e62-4b0c-bccb-5b7501e65728"
    ],
    "year": 18
  },
  "summer-day": {
    "name": "Summer day",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work1%2Fsummer-day.jpg?alt=media&token=0865d196-573f-4988-96ad-587e8d2d6bc9"
    ],
    "year": 18
  },
  "three-monkeys": {
    "name": "Three monkeys",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work1%2Fthree-monkeys.jpg?alt=media&token=3148f0ec-7dfd-4d7e-967e-52546939378b"
    ],
    "year": 18
  },
  "ugly-duckling": {
    "name": "Ugly duckling",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work1%2Fugly-duckling.jpg?alt=media&token=89457e51-536a-4f25-875b-7c8618ec6df4"
    ],
    "year": 18
  },
  "winelover": {
    "name": "Winelover",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work1%2Fwinelover18.JPG?alt=media&token=316fc211-77ba-4ce1-a2c1-e9ca3a4441e1"
    ],
    "year": 18
  },
  "zbg-condensed-milk": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work1%2Fzbg-condensed-milk.jpg?alt=media&token=a9eeb339-d90d-4801-a477-e3ff14cd4952"
    ],
    "year": 18
  }
}

export const works2 = {
  "apple-tattoo": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fapple-tattoo.jpg?alt=media&token=b3a489f3-3a5e-4782-85f8-10070d85eef2"
    ],
    "year": 18
  },
  "bird-on-a-rowan-tree": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fbird-on-a-rowan-tree.jpg?alt=media&token=09054b0a-4799-4f6b-a1d1-0b99a2d0f9f1"
    ],
    "year": 18
  },
  "bird-with-fish-tattoo": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fbird-with-fish-tattoo.jpg?alt=media&token=df364b1e-0dfb-4477-a6f3-b5f78359f0ab"
    ],
    "year": 18
  },
  "blue-stone": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fblue-stone.jpg?alt=media&token=4b3369f8-3226-44bf-bda2-9a1e5b0c9f0f"
    ],
    "year": 18
  },
  "bonjour-tattoo": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fbonjour-tattoo.jpg?alt=media&token=8d732a40-654e-432a-8383-776aee6f035b"
    ],
    "year": 18
  },
  "brick-in-the-wall": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fbrick-in-the-wall.jpg?alt=media&token=ac66dee0-3492-4c37-8dca-72faaee81df6"
    ],
    "year": 18
  },
  "butterflies": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fbutterflies.jpg?alt=media&token=8c846fa4-f841-47ba-a064-7cb5251450ab"
    ],
    "year": 18
  },
  "death-violence-love": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fdeath-violence-love.jpg?alt=media&token=8e8ecda2-2d38-43d6-8d20-71a4cbf6d890"
    ],
    "year": 18
  },
  "devil-tattoo": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fdevil-tattoo.jpg?alt=media&token=cc7818d5-3bab-4154-8c41-89d307b194cd"
    ],
    "year": 18
  },
  "dogs-love": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fdogs-love.jpg?alt=media&token=2fa50197-eb7a-43d2-9605-2a0d491dd830"
    ],
    "year": 18
  },
  "dolphin-sketch": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fdolphin-sketch.jpg?alt=media&token=cb53c840-aaab-495e-81f0-6ed69dafe7b5"
    ],
    "year": 18
  },
  "enjoy-every-day-tattoo": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fenjoy-every-day-tatto.jpg?alt=media&token=4dc51c43-08fb-49b5-bdd6-26a8436aa3a7"
    ],
    "year": 18
  },
  "fishing-tattoo": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Ffishing-tattoo.jpg?alt=media&token=1a7062a6-85b1-433e-91a2-80a850132bb5"
    ],
    "year": 18
  },
  "free-space": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Ffree-space.jpg?alt=media&token=c6492e6a-aaf7-42a6-8769-db15c72fcd8e"
    ],
    "year": 18
  },
  "fuck-thoughts": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Ffuck-thoughts.jpg?alt=media&token=f8a26783-3f4a-43e7-9b58-f57384cc09b0"
    ],
    "year": 18
  },
  "gambling-life": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fgambling-life.jpg?alt=media&token=65c130b2-969d-464e-9dc8-b1f52ed6b7d7"
    ],
    "year": 18
  },
  "google-porno-tattoo": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fgoogle-porno-tatto.jpg?alt=media&token=ebe91b09-2c2e-46b1-97f1-47c5cb357c53"
    ],
    "year": 18
  },
  "graveyard": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fgraveyard.jpg?alt=media&token=a2e07867-6e0f-495e-9fc9-036caa6eaa92"
    ],
    "year": 18
  },
  "harlem": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fharlem.jpg?alt=media&token=cd8b38ee-38c1-4d3b-99d6-26d7a6c04011"
    ],
    "year": 18
  },
  "holzherz": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fholzherz.jpg?alt=media&token=2942658c-50ac-4eb3-95ee-b6c87ca23150"
    ],
    "year": 18
  },
  "humans-and-pigs": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fhumans-and-pigs.jpg?alt=media&token=722e84f7-ffe7-42ea-a524-d180407b68d2"
    ],
    "year": 18
  },
  "i-am-cat": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fi-am-cat-street.jpg?alt=media&token=e8be3e21-c1f3-4677-b8bb-7d3cd01ee36e"
    ],
    "year": 18
  },
  "ikea-mc-dolphin": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fikea-mc-doplhin-street.jpg?alt=media&token=9635a958-2852-4500-a7fb-6f41de621a43"
    ],
    "year": 18
  },
  "k": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fk.jpg?alt=media&token=1d2ac489-da33-4709-8ab9-7892175d8db6"
    ],
    "year": 18
  },
  "man-in-the-hat": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fman-in-hat-street.jpg?alt=media&token=d8f48ada-56a0-4a95-8aca-9a9cec79bedc"
    ],
    "year": 18
  },
  "meat-machine": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fmeat-machine.jpg?alt=media&token=b953b849-667a-4589-92d7-3d9c7b108e7d"
    ],
    "year": 18
  },
  "not-drunk": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fnot-drunk.jpg?alt=media&token=a6453c1a-c3fa-4a38-8d79-cd6fa08a2910"
    ],
    "year": 18
  },
  "not-funny": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fnot-funny.jpg?alt=media&token=90c6210c-f0c6-4a30-ac2b-1c6c7a14a3fc"
    ],
    "year": 18
  },
  "onion": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fonion.jpg?alt=media&token=aa6d9f10-0416-4a38-b449-7b9fb91ceb43"
    ],
    "year": 18
  },
  "peac": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fpeac.jpg?alt=media&token=548248cc-9f91-4e01-8079-9508be44487e"
    ],
    "year": 18
  },
  "piggy": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fpiggy-street.jpg?alt=media&token=04b0cc19-8251-4bc9-9400-c3a7d7c43240"
    ],
    "year": 18
  },
  "pink-cart": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fpink-cart.jpg?alt=media&token=21831b80-a952-425c-af13-8e3d458ea2d4"
    ],
    "year": 18
  },
  "pink-chicken": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fpink-chicken.jpg?alt=media&token=3f2b5b9c-5969-401e-9f74-7cf9f0973385"
    ],
    "year": 18
  },
  "pink-toilet": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fpink-toilet.jpg?alt=media&token=903f23c2-e54a-4407-a8bd-e7a312da1e8f"
    ],
    "year": 18
  },
  "present-for-ex": {
    "name": "Present for ex",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fpresent18.jpg?alt=media&token=7633f6ad-e298-43e3-83ee-5f5d557c7378"
    ],
    "year": 18
  },
  "red-tear": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fred-tear.jpg?alt=media&token=f75a5a29-f631-4827-a6de-8c0cf9f89de9"
    ],
    "year": 18
  },
  "skate-figure": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fskate-figure.jpg?alt=media&token=69a5d566-5677-4847-b1bd-656a2ba20f5f"
    ],
    "year": 18
  },
  "sorry-not-today": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fsorry-not-today.jpg?alt=media&token=f94e6cb1-755f-4383-b6b4-b4bcad04cee4"
    ],
    "year": 18
  },
  "street-art-1": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fstreet-art-1.jpg?alt=media&token=fae0814c-c549-481d-ad80-48a1154864be"
    ],
    "year": 18
  },
  "street-art-2": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fstreet-art-2.jpg?alt=media&token=503440be-98a4-4a3d-b461-37c39dad6051"
    ],
    "year": 18
  },
  "street-art-3": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fstreet-art-3.jpg?alt=media&token=744dcf05-77f1-4d43-b9fc-cfd010e5be14"
    ],
    "year": 18
  },
  "street-best-of-the-gallery": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fstreet-best-of-the-gallery.jpg?alt=media&token=f7cb78d9-2213-493d-85b6-1a7ee0f4f0ad"
    ],
    "year": 18
  },
  "tities-street": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Ftities-street.jpg?alt=media&token=9ef7dc26-3c51-4072-bf20-ceffe5b652c2"
    ],
    "year": 18
  },
  "two-birds-tattoo": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Ftwo-birds-tattoo.jpg?alt=media&token=1dc612e7-6c61-4cf0-9b29-008174fe1ebf"
    ],
    "year": 18
  },
  "ussr-carrida": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fussr-carrida.jpg?alt=media&token=247ef3fe-69e3-4bf0-8546-6fb731e51cd2"
    ],
    "year": 18
  },
  "wall-18": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fwall-18.jpg?alt=media&token=6bbddda2-5d25-467e-a8d0-45726980d22d"
    ],
    "year": 18
  },
  "wall-care-2": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fwall-care-2.jpg?alt=media&token=3778d9a8-64b2-4149-ba75-67e97d84b9a5"
    ],
    "year": 18
  },
  "wall-care-1": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fwall-care.jpg?alt=media&token=0f8b69ea-0ef5-4941-9cb9-849557ae1c01"
    ],
    "year": 18
  },
  "words-like-water-tattoo": {
    "name": "",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2Fwords-are-water-tattoo.jpg?alt=media&token=1af71f19-a4e9-4f47-97c5-fd0feab3f3cf"
    ],
    "year": 18
  },
  "blue": {
    "name": "Blue",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2F367blue18.jpg?alt=media&token=6d261181-bdab-40e8-a9c2-a0787937b2ee"
    ],
    "year": 18
  },
  "masturbate-to": {
    "name": "Masturbate to",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2F367masturbateto19.jpg?alt=media&token=fd049a71-6422-4464-8c09-5d682299b394"
    ],
    "year": 19
  },
  "present-for-ex-18": {
    "name": "Present for ex",
    "text": "",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/work2%2F367present.jpg?alt=media&token=2093045e-2105-4a7c-bdbb-1a91ba21a808"
    ],
    "year": 18
  }
}

import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import TextSection from '../common/TextSection'

class Exhibition extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const { exhibition } = this.props

    if (!exhibition) return <Redirect to='/' />

    const { images, name, date, place, description, link } = exhibition

    return (
      <div className='exhibition'>
        <div className='exhibition__inner'>
          <h1 className='exhibition__header page-header base-header'>
            {name}
          </h1>
          {
            description &&
            <TextSection textParts={description.split('\n').filter(Boolean)} />
          }
          <div className='exhibition__date base-text'>
            date: <span className='font-bold'>{date}</span>
          </div>
          <div className='exhibition__place base-text'>
            place: <span className='font-bold'>{place}</span>
          </div>
          {
            link ?
            <div className='exhibition__place base-text'>
              <a href={link} target='_blank' rel='noopener noreferrer' className='font-bold'>{link}</a>
            </div> : null
          }
        </div>

        <img src={images[0]} className='exhibition__img' width='280' height='200' alt={`${name} event`} />
      </div>
    )
  }
}

export default Exhibition

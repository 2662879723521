import React, { Component } from 'react'

class Shop extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const { photos } = this.props

    return (
      <div className='photo'>
        <h1 className='photo__header page-header base-header'>
          Photo
        </h1>
        <div className='photo__content base-text'>
          {
            photos.map((photo, index) => {
              return (
                <img key={photo} src={photo} width='250' alt={`photo_${index}}`} />
              )
            })
          }
        </div>
      </div>
    )
  }
}

export default Shop

import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

class Work extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const { work } = this.props

    if (!work) return <Redirect to='/' />

    const { name, images, year } = work

    return (
      <div className='work'>
        <img src={images[0]} className='work__img' width='280' height='200' alt={`${name} work`} />
        <div className='work__capture'>
          <h1 className='work__name base-header'>
            {name}
          </h1>
          <div className='work__date base-header'>
            {year}
          </div>
        </div>

      </div>
    )
  }
}

export default Work

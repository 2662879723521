import React from 'react'

function Loader() {
  return (
    <div className='page-header base-header'>
      <div className=''>Loading...</div>
    </div>
  )
}

export default Loader

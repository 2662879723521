import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
// import AddEventForm from '../events/AddEventForm'
// import ChangeTickerForm from '../ticker/ChangeTickerForm'

class Admin extends Component {
  // state = {
  //   activeForm: ''
  // }
  //
  // selectAction = formName => () => this.setState({ activeForm: formName })
  //
  // closeForm = (name, value) => {
  //   const { saveNewTicker, saveNewEvent } = this.props
  //
  //   if (name === 'ticker') {
  //     this.setState({ activeForm: '' }, saveNewTicker(value))
  //   } else if (name === 'event') {
  //     this.setState({ activeForm: '' }, saveNewEvent(value))
  //   } else {
  //     this.setState({ activeForm: '' })
  //   }
  // }

  render() {
    // const { activeForm } = this.state
    const { user } = this.props

    if (!user) return <Redirect to='/' />

    return (
      <div>
        <h1 className='page-header base-header'>Admin</h1>
        <div>
          content
        </div>
      </div>
    )
    // switch (activeForm) {
    //   case 'addEventForm':
    //     return <AddEventForm closeForm={this.closeForm} />
    //   case 'editTickerForm':
    //     return <ChangeTickerForm closeForm={this.closeForm} ticker={ticker} />
    //   default:
    //     return (
    //       <div className="content-page block-with-padding">
    //         <p className="base-paragraph">
    //           <button className="button" onClick={this.selectAction('addEventForm')}>
    //             Add New Event
    //           </button>
    //         </p>
    //
    //         <p className="base-paragraph">
    //           <button className="button" onClick={this.selectAction('editTickerForm')}>
    //             Edit Ticker
    //           </button>
    //         </p>
    //       </div>
    //     )
    // }
  }
}

export default Admin

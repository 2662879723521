export const exhibitions = {
  "fabrik": {
    "name": "ФАБРИ́К",
    "description": "First and last exhibition of «ФАБРИ́К». Artists Victor Zabuga and Gleb Pesoc. You can not come, it will not stop us from painting.",
    "date": "July 17-20 2018",
    "place": "Клуб, Borovaya 116, Saint Petersburg, Russia",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/exhibitions%2Ffabrik.jpg?alt=media&token=9c63125a-60ee-4ffa-b6de-4f0b4fa29bee"
    ],
    "created": 1531774800000
  },
  "zv": {
    "name": "ЗВ",
    "description": "Street art festival.",
    "date": "June 1 2019",
    "place": "Secret place, Saint Petersburg, Russia",
    "link": "http://x-palace.org",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/exhibitions%2Fzv.jpg?alt=media&token=16374503-7f0c-4fd2-8e57-e0a510c2713d"
    ],
    "created": 1531774800000
  },
  "i-ya": {
    "name": "И,я",
    "description": "А и Б сидели на трубе , А упала , Б пропала , что осталось на трубе?... Правильно И , именно она убрала все лишнее и пришла к минимализму . К этому всему я добавил своё Я , но не стал его ставить выше происходящего,от этого и название « И,я ». Это моя первая сольная выставка и тут не будет какой - то тематики,это будут абсолютно разные работы и ситуации , собранные за год. \n вход : free\n через бар-бар\n \n Музыкальное сопровождение :\n МЦ Бездомный\nОля Столовая\nРита К.\nR V A",
    "date": "24 , 25 , 26 СЕНТЯБРЯ 2019",
    "time": "12:00-19:00",
    "place": "Севкабель Порт",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/exhibitions%2Fzabuga-i-ya-web.jpg?alt=media&token=08238e08-9e77-4c16-aced-bd2a82bc80d9"
    ],
    "created": 1531774800000
  },
  "kak-est": {
    "name": "КАК ЕСТЬ ?...",
    "description": " – Смотря как преподносят, как готовят, ну и не маловажно что ты ешь сам. Совместная выставка Виктора Забуга и товарища Ялты 1970. Нас объединяет в первую очередь уличная культура, экспериментальный подход к работе и поиск новых техник. Рисование как вид трансформации от повседневной рутины, момент когда тобой владеет идея и ничего лишнего. Будет много новых работ, а так же табун ковров и мелодии калькуляторов Азии. Ждём всех чтобы показать всё КАК ЕСТЬ!\n Вход: FREE",
    "date": "28 сентября – 1 октября 2020, 12:00 – 20:00",
    "time": "12:00–20:00",
    "place": "Санкт-Петербург, Севкабель Порт, пространство «ВОЛЬТ»",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/exhibitions%2Fkak-est-367-small.JPG?alt=media&token=0f5f0ca9-f0f0-446b-875e-9cdc7edbaaf8"
    ],
    "created": 1531774800000
  },
  "mrz": {
    "name": "mr.Z",
    "description": "mr.Z — загадочная фигура, которая раздаёт карты зрителю, но в рукаве всегда имеет туз. Фантом, который появляется в нужный момент, чтобы спасти галереи, приоткрывая занавесу тайны над своими работами. Художник активно создает двойственную идентичность ночного героя — анонимные плакаты, нетривиальные фразы и сюрреалистические портреты. Кто же скрывается за маской? Ребёнок, которому в руки дали краски. Играя техникой, он приглашает зрителя в свой собственный мир цвета, формы, эксперимента и переживаний .",
    "date": "13 января – 26 января 2021, 12:00 – 22:00",
    "time": "12:00–22:00",
    "place": "Санкт-Петербург, Arts Square Gallery, ул. Итальянская 5",
    "images": [
      "https://firebasestorage.googleapis.com/v0/b/zabuga-67ed0.appspot.com/o/exhibitions%2Fmrz.JPG?alt=media&token=a9f573c0-faf2-43d4-88c6-785ab935df9e"
    ],
    "created": 1639751789114
  }
}
